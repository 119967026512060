import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { createPortal } from 'react-dom';
import { useFormState } from 'react-hook-form';

import { useCommonFormContext } from '../functional';

export interface SubmitButtonProps extends ButtonProps {
  container?: Element | null;
  waitForDirty?: boolean;
  waitForValid?: boolean;
}

export const SubmitButton = forwardRef<SubmitButtonProps, 'button'>(
  ({ container, waitForDirty, waitForValid, ...props }, ref) => {
    const { isSubmitting, isDirty, isValid } = useFormState();
    const { isReadOnly } = useCommonFormContext() || {};

    const button = (
      <Button
        isLoading={isSubmitting}
        isDisabled={
          isReadOnly || (waitForDirty && !isDirty) || (waitForValid && !isValid)
        }
        ml="auto"
        fontWeight="medium"
        colorScheme="primary"
        w={{ mobile: 'full', desktop: '200px' }}
        {...props}
        ref={ref}
      />
    );

    if (!container) {
      return button;
    }

    return createPortal(button, container);
  },
);
