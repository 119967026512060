import { CountryCode, countryCodes } from '@proptly/shared';

export const countryCodeDisplayName = (
  countryCode: string,
  locales?: Intl.LocalesArgument,
) => {
  try {
    return (
      new Intl.DisplayNames(locales, { type: 'region' }).of(countryCode) ??
      countryCode
    );
  } catch {
    return countryCode;
  }
};

export const getCountryCodes = () => {
  return countryCodes as unknown as CountryCode[];
};

export const getDefaultCountryCode = (): CountryCode => 'NO';
